import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    redirect:"index",
    meta:{
      showTab:true
    }
  },
  {
    path: '/index',
    name: '首页',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue'),
    meta:{
      title:'首页',
      login:true
    }
  },
  {
    path: '/login',
    name: '信用卡预申请',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue'),
    meta:{
      title:'信用卡预申请',
      login:false
    }
  },{
    path: '/customer',
    name: '推广客户',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/index.vue'),
    meta:{
      title:'推广客户',
      login:true
    }
  },{
    path: '/center',
    name: '账户信息',
    component: () => import(/* webpackChunkName: "about" */ '../views/center/index.vue'),
    meta:{
      title:'账户信息',
      login:true
    }
  },{
    path: '/code',
    name: '推广二维码',
    component: () => import(/* webpackChunkName: "about" */ '../views/code/index.vue'),
    meta:{
      title:'推广二维码',
      login:true
    }
  },{
    path: '/entrance',
    name: '信用卡预申请',
    component: () => import(/* webpackChunkName: "about" */ '../views/entrance/entrance.vue'),
    meta:{
      title:'信用卡预申请',
      login:false
    }
  },{
    path: '/base',
    name: '信用卡预申请',
    component: () => import(/* webpackChunkName: "about" */ '../views/base/base.vue'),
    meta:{
      title:'信用卡预申请',
      login:false
    }
  },{
    path: '/info',
    name: '信用卡预申请',
    component: () => import(/* webpackChunkName: "about" */ '../views/info/info.vue'),
    meta:{
      title:'信用卡预申请',
      login:false
    }
  },
  // 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
