import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.config.productionTip = false
import {
  Toast,
} from 'vant';

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (!to.meta.login) {
    next()
  } else
  if ((to.meta.login && localStorage.getItem('userInfo'))) {
    next()
  } else {
    Toast('登陆失效，请重新登陆')
    setTimeout(() => {
      next('/login')
    }, 1500);
  }
})
function setRem() {
  const baseWidth = 750; // 基准宽度
  const scale = document.documentElement.clientWidth / baseWidth; // 缩放比例
  document.documentElement.style.fontSize = scale * 100 + 'px'; // 设置根元素的字体大小
}
 
// 初始化设置
setRem();
 
// 监听窗口大小变化
window.addEventListener('resize', setRem);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
