<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: "app",
  data() {
    return {
    }
  },
  
  created(){
   
  },
  methods:{
   
  }
};
</script>
<style>

div {
  /* box-sizing: border-box; */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}
</style>
